import { addAlert, removeAlert, updateGlobalRead } from "@/store/redux/events/eventsSlice"
import { useAppDispatch } from "@/store/redux/hooks"
import { RootState } from "@/store/redux/store"
import { useSelector } from "react-redux"

const useEvents = () => {
    const dispatch = useAppDispatch()

    const eventsState = useSelector((state: RootState) => state.eventsStore);

    const getEventsForOrder = (orderId: string) => {
        return eventsState?.[orderId] ?? {};
    }

    const removeAlertForOrderId = (orderId: string, alerts: any) => {
        dispatch(removeAlert({orderId: orderId, alerts: alerts}));
    }


    const updateGlobalReadForOrder = (orderId: string, gloablRead: boolean) => {
        dispatch(updateGlobalRead({orderId: orderId, globalRead: gloablRead}));
    }


    const addAlertForOrderId = (orderId: string, alert: any, globalRead: boolean) => {
        dispatch(addAlert({orderId: orderId, alert: alert, globalRead: globalRead}))
    }

    return {
        eventsState,
        addAlertForOrderId,
        getEventsForOrder,
        removeAlertForOrderId,
        updateGlobalReadForOrder
    }
}

export default useEvents;