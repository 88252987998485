import { useCallback, useState } from 'react'
import { SendMedia } from './SendMedia'
import { SendMediaDisplay } from './SendMediaDisplay'
import { SendAudio } from './SendAudio'
import {
  fetchUploadSignedApi,
  sendMessageApi,
  UploadMediaApi,
} from '@/api/services/chat.service'
import SendText from './SendText'
import toast from 'react-hot-toast'

export default function SendMessage({
  setMessages,
  documentSection,
  setDocumentSection,
  channel_id,
  sender_id,
  sender_name,
  sender_role,
  sender_platform,
  sender_contact,
}: any) {
  const [message, setMessage] = useState<string>('')
  const [fileSelected, setFileSelected] = useState<boolean>(false)
  const [recordingOn, setRecordingOn] = useState<boolean>(false)
  const [displayMediaData, setDisplayMediaData] = useState<any>(null)
  const [sendingMedia, setSendingMedia] = useState<boolean>(false)

  // send text message
  const sendMessage = useCallback(
    (category, type, assetUrl, format) => {
      const data: any = {
        category: category,
        type: type,
        format: format,
        assetUrl: assetUrl,
        content: checkValidMessage(message),
        senderId: sender_id,
        senderRole: sender_role,
        senderName: sender_name,
        senderPlatform: sender_platform,
        senderContact: sender_contact,
      }
      if (assetUrl || data.content) {
        sendMessageApi(channel_id, data)
          .then(() => {
            setFileSelected(false)
            setDisplayMediaData(null)
            setMessage('')
            setDocumentSection(false)
            setSendingMedia(false)
          })
          .catch((error) => {
            setSendingMedia(false)
            toast.error('Not able to send message')
            console.error(error)
          })
        setMessage('')
      }
    },
    [message, setMessages]
  )

  // remove if the last line is empty
  function checkValidMessage(message) {
    const temp = message?.split('\n')
    let i = temp?.length - 1
    let flag = false
    do {
      if (temp[i] == '') {
        temp.pop()
      } else flag = true
      --i
    } while (!flag || i == 0)
    return temp?.length ? temp?.join('\n') : ''
  }

  // send media + text message
  const sendMediaDoc = useCallback(
    (MediaData) => {
      let fileName: string = ''
      if (MediaData) {
        fileName = MediaData?.modifiedFileName || ''
        fetchUploadSignedApi(channel_id, fileName)
          .then((signedUrl: any) => {
            UploadMediaApi(
              signedUrl?.data?.data?.signedUrl,
              MediaData?.mediaData,
              MediaData?.format
            ).then(() => {
              sendMessage(
                MediaData?.category,
                MediaData?.type,
                signedUrl?.data?.data?.signedUrl.split('?')[0],
                MediaData?.format
              )
            })
          })
          .catch((error) => {
            toast.error('Not able to send message')
            console.error(error)
          })
      }
    },
    [message, displayMediaData]
  )

  const getMediaData = (fileData, category, type) => {
    const temp = fileData?.item(0)?.name?.split('.')
    const modifiedFileName =
      type + '_' + Date.now() + '.' + temp[temp.length - 1]
    return {
      src: URL.createObjectURL(fileData[0]),
      modifiedFileName: `${modifiedFileName}`,
      filename: fileData?.item(0)?.name,
      filesize: fileData?.item(0)?.size,
      category: category,
      type:
        type == 'document' || type == 'application'
          ? ['pdf', 'docx', 'doc'].includes(temp[temp.length - 1])
            ? 'document'
            : fileData[0].type.split('/')[0]
          : type,
      format: temp[temp.length - 1],
    }
  }

  // image/video to binary
  const convertImageVideoToBinary = (fileData) => {
    if (fileData[0]) {
      const imageData = getMediaData(
        fileData,
        'media',
        fileData[0].type.split('/')[0]
      )
      const reader: any = new FileReader()
      const uploadFile = (inputElement) => {
        const file = inputElement[0]
        reader.onloadend = () => {
          /******************* for Binary ***********************/
          const data = reader?.result?.split(',')[1]
          const binaryBlob = atob(data)

          const len = binaryBlob?.length
          const bytes = new Uint8Array(len) // Create a Uint8Array to hold the binary data

          for (let i = 0; i < len; i++) {
            bytes[i] = binaryBlob?.charCodeAt(i) // Convert each character to binary
          }

          imageData['mediaData'] = bytes || ''
        }
        reader.readAsDataURL(file)
      }
      uploadFile(fileData)
      setDisplayMediaData(imageData)
      setFileSelected(true)
    }
  }

  // document to binary
  const convertDocToBinary = (fileData) => {
    if (fileData[0]) {
      const docData = getMediaData(fileData, 'media', 'document')
      const reader = new FileReader()
      reader.onload = function (event) {
        const mediaData = event?.target?.result // This is the binary data of the PDF
        const formData = new FormData()
        formData.append(
          'pdf',
          new Blob([mediaData], { type: 'application/pdf' }),
          docData.modifiedFileName
        )
        docData['mediaData'] = mediaData || ''
        docData['formData'] = formData || ''
      }
      reader.readAsArrayBuffer(fileData[0])
      setDisplayMediaData(docData)
      setFileSelected(true)
    }
  }

  return (
    <>
      <div className='absolute bottom-0 left-0 z-50 min-h-10 w-full'>
        <SendMedia
          documentSection={documentSection}
          convertImageVideoToBinary={convertImageVideoToBinary}
          convertDocToBinary={convertDocToBinary}
        />
        <div className='flex min-h-11 w-full items-end justify-around gap-2 border-t bg-white p-2 dark:bg-slate-950 '>
          <SendText
            recordingOn={recordingOn}
            documentSection={documentSection}
            setDocumentSection={setDocumentSection}
            message={message}
            setMessage={setMessage}
            sendMessage={sendMessage}
          />
          <SendAudio
            documentSection={documentSection}
            setRecordingOn={setRecordingOn}
            setDisplayMediaData={setDisplayMediaData}
            message={message}
            sendMediaDoc={sendMediaDoc}
          />
        </div>
      </div>
      {fileSelected && (
        <SendMediaDisplay
          sendingMedia={sendingMedia}
          setSendingMedia={setSendingMedia}
          sendMediaDoc={sendMediaDoc}
          displayMediaData={displayMediaData}
          setFileSelected={setFileSelected}
          setDisplayMediaData={setDisplayMediaData}
          message={message}
          setMessage={setMessage}
          setDocumentSection={setDocumentSection}
        />
      )}
    </>
  )
}
