export const colors = [
  '#DC6803',
  '#DD2590',
  '#7F56D9',
  '#175CD3',
  '#008710',
  '#E9231D',
  '#00A3AE',
  '#A87615',
  '#01B01D',
  '#B90606',
  // '#E6E6FA',
]

export const contentType = {
  // Photos
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  heic: 'image/heic',
  heif: 'image/heif',
  // Videos
  mp4: 'video/mp4',
  mov: 'video/quicktime',
  // Audio
  aac: 'audio/aac',
  webm: 'video/webm',
  m4a: 'audio/mp4',
  wav: 'audio/wav',
  amr: 'audio/amr',
  mp3: 'audio/mpeg',
  // Documents
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
}

export const allowedFileFormats = {
  all: [
    'png',
    'jpeg',
    'jpg',
    'heic',
    'heif',
    'mp3',
    'mp4',
    'mov',
    'pdf',
    'doc',
    'docx',
    'aac',
    'm4a',
    'wav',
    'amr',
  ],
  image: 'image/png, image/jpeg, image/jpg, image/heic, image/heif',
  video: '.mp4, .mov',
  document: 'application/pdf, .doc, .docx',
}
