import { allowedFileFormats } from '@/constant/chats.constants'
import { IconCamera, IconPaperclip, IconPhoto } from '@tabler/icons-react'

export function SendMedia({
  documentSection,
  convertImageVideoToBinary,
  convertDocToBinary,
}) {
  return (
    <div
      className={`flex ${documentSection ? 'visible h-48' : 'invisible h-0'} w-full items-center justify-around gap-2 overflow-hidden border-t bg-white p-2 transition-[height,visibility] dark:bg-slate-800`}
    >
      <div
        className={`transition-[opacity, height] relative grid cursor-pointer place-content-center overflow-hidden rounded-full bg-blue-600 ${documentSection ? 'h-20 w-20 opacity-100 ' : 'h-0 w-0 opacity-0'}`}
      >
        <input
          id='documentInput'
          type='file'
          name='upload'
          accept={allowedFileFormats?.document}
          className={`absolute left-0 top-0 z-10 h-20 w-20 cursor-pointer opacity-0`}
          onChange={(e: any) => convertDocToBinary(e?.target?.files)}
        ></input>
        <IconPaperclip color='white' />
      </div>
      <div
        className={`relative grid h-20 w-20 cursor-pointer place-content-center overflow-hidden rounded-full bg-green-600 transition-[opacity] ${documentSection ? 'opacity-100' : 'opacity-0'}`}
      >
        <input
          id='photoInput'
          type='file'
          name='upload'
          accept={`${allowedFileFormats?.image}, ${allowedFileFormats?.video}`}
          className='absolute left-0 top-0 z-10 h-20 w-20 cursor-pointer opacity-0'
          onChange={(e: any) => convertImageVideoToBinary(e?.target?.files)}
        ></input>
        <IconPhoto color='white' />
      </div>
      <div
        className={`relative grid h-20 w-20 cursor-pointer place-content-center overflow-hidden rounded-full bg-pink-600 transition-[opacity] ${documentSection ? 'opacity-100' : 'opacity-0'}`}
      >
        <input
          id='cameraInput'
          type='file'
          name='upload'
          accept={`${allowedFileFormats?.image}, ${allowedFileFormats?.video}; capture=camcorder`}
          capture
          className='absolute left-0 top-0 z-10 h-20 w-20 cursor-pointer opacity-0'
          onChange={(e: any) => convertImageVideoToBinary(e?.target?.files)}
        ></input>
        <IconCamera color='white' />
      </div>
    </div>
  )
}
