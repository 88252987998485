import { IconPlus, IconSend } from '@tabler/icons-react'

function SendText({
  documentSection,
  setDocumentSection,
  message,
  setMessage,
  sendMessage,
  recordingOn,
}) {
  return (
    <>
      {!recordingOn && (
        <>
          <button
            className='p-2.5'
            onClick={() => setDocumentSection((val) => !val)}
          >
            <IconPlus
              className={`transition-[transform,color] ${documentSection ? 'rotate-45 text-red-400' : 'rotate-0'}`}
              width={20}
            />
          </button>
          <textarea
            onChange={(e: any) => {
              setMessage(e?.target?.value)
            }}
            onKeyDown={(e: any) => {
              if (e?.keyCode == 13 && e?.shiftKey == false) {
                e.preventDefault()
                sendMessage('text', 'text', '', 'text')
              }
            }}
            rows={1}
            className='h-full max-h-20 min-h-11 w-[90%] rounded-xl border border-slate-200 bg-white px-5 pt-2 placeholder-slate-300 focus:border-slate-400 focus:outline-none dark:border-slate-700 dark:bg-slate-900 dark:placeholder-slate-600 dark:focus:border-slate-500'
            placeholder='Add notes'
            value={message}
          />
        </>
      )}
      {message && (
        <button
          onClick={() => sendMessage('text', 'text', '', 'text')}
          className='rounded-full bg-gradient-to-r from-purple-400 to-violet-600 p-2.5 dark:from-blue-400 dark:to-indigo-600'
        >
          <IconSend color='white' />
        </button>
      )}
    </>
  )
}

export default SendText
