import RecordPlugin from 'wavesurfer.js/dist/plugins/record.esm.js'
import WaveSurfer from 'wavesurfer.js'
import { useEffect, useRef, useState } from 'react'

const useAudioRecording = (mic) => {
  const scrollingWaveform: boolean = true
  const recordButton = useRef<HTMLButtonElement>(null)
  const pauseButton = useRef<HTMLButtonElement>(null)
  const progress = useRef<HTMLParagraphElement>(null)
  const [isRecordingPaused, setIsRecordingPaused] = useState<boolean>(true)
  const [record, setRecord] = useState<any>(null)
  const [wavesurfer, setWaveSurfer] = useState<any>(null)
  const [isRecordPlaying, setIsRecordPlaying] = useState<boolean>(false)
  const [micOn, setMicOn] = useState<boolean>(false)
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null)
  const CreateWaveSurfer = (mic) => {
    if (!mic) return
    // Create an instance of WaveSurfer
    if (wavesurfer) {
      wavesurfer.destroy()
      setWaveSurfer(null)
    }
    setWaveSurfer(
      WaveSurfer.create({
        container: mic,
        waveColor: '#4b6b8e',
        progressColor: '#354c5b',
        height: 10,
        cursorColor: '#ddd5e9',
        cursorWidth: 2,
        // mediaControls: true,
        dragToSeek: true,
        minPxPerSec: 0.1,
        // barAlign:  "bottom",
        barHeight: 3,
        autoCenter: true,
        autoScroll: true,
        // Set a bar width
        barWidth: 1,
        // Optionally, specify the spacing between bars
        barGap: 2,
        // And the bar radius
        barRadius: 20,
      })
    )

    pauseButton.current.style.display = 'none'
  }

  useEffect(() => {
    if (!record) return
    StartRecording()
  }, [record])

  record &&
    !isRecordingPaused &&
    record?.on('record-progress', (time: any) => {
      updateProgress(time)
    })

  record &&
    wavesurfer &&
    isRecordingPaused &&
    isRecordPlaying &&
    wavesurfer?.on('timeupdate', (time: any) => {
      updateProgress(time * 1000)
    })

  useEffect(() => {
    if (!wavesurfer) return
    setRecord(() =>
      wavesurfer.registerPlugin(
        RecordPlugin.create({ scrollingWaveform, renderRecordedAudio: true })
      )
    )
    wavesurfer.on('finish', () => {
      setIsRecordPlaying(false)
    })

    return () => {
      wavesurfer.destroy()
      setWaveSurfer(null)
    }
  }, [wavesurfer])

  useEffect(() => {
    record &&
      record.on('record-end', (blob) => {
        setAudioBlob(blob)
      })
  }, [record])

  wavesurfer &&
    wavesurfer.on('finish', () => {
      setIsRecordPlaying(false)
    })

  const PlayRecording = () => {
    wavesurfer && wavesurfer.playPause()
  }

  const DeleteRecording = () => {
    record.stopRecording()
    setMicOn(false)
    setIsRecordingPaused(true)
    setIsRecordPlaying(false)
    recordButton.current.style.display = 'inline'
    setRecord(null)
    if (wavesurfer) {
      wavesurfer.destroy()
      setWaveSurfer(null)
    }
  }

  const updateProgress = (time: any) => {
    // time will be in milliseconds, convert it to mm:ss format
    const formattedTime = [
      Math.floor((time % 3600000) / 60000), // minutes
      Math.floor((time % 60000) / 1000), // seconds
    ]
      .map((v) => (v < 10 ? '0' + v : v))
      .join(':')
    progress.current.textContent = formattedTime
  }

  const PauseRecording = () => {
    if (record?.isPaused()) {
      record?.resumeRecording()
      setIsRecordingPaused(false)
      pauseButton.current.style.display = 'inline'
      return
    }
    pauseButton.current.style.display = 'inline'
    setIsRecordingPaused(true)

    record?.pauseRecording()
  }

  // Record button
  const StartRecording = () => {
    RecordPlugin.getAvailableAudioDevices().then((devices) => {
      const deviceId = devices?.[0].deviceId
      setIsRecordingPaused(false)
      record?.startRecording({ deviceId }).then(() => {
        recordButton.current.disabled = false
        recordButton.current.style.display = 'none'
        pauseButton.current.style.display = 'inline'
      })
    })
  }

  return {
    mic,
    recordButton,
    pauseButton,
    progress,
    isRecordingPaused,
    isRecordPlaying,
    micOn,
    wavesurfer,
    audioBlob,
    setIsRecordingPaused,
    setIsRecordPlaying,
    setMicOn,
    CreateWaveSurfer,
    PlayRecording,
    DeleteRecording,
    PauseRecording,
    StartRecording,
  }
}

export default useAudioRecording
