import Pubnub from 'pubnub'

export class OrderPubNub {
  clients = {}
  uuids = []

  //  UUIDs
  constructor({ uuids }: { uuids: string[] }) {
    this.uuids = uuids

    this.uuids.forEach((uuid: any) => {
      if (uuid) {
        this.clients[uuid] = new Pubnub({
          publishKey: import.meta.env.VITE_PUBNUB_PUBLISH_KEY,
          subscribeKey: import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY,
          uuid: uuid,
        })
      }
    })
  }

  subscribe = (uuid: string, chanelName: string | string[], callback: any) => {
    if (this.clients[uuid]) {
      this.clients[uuid].addListener({
        message: (data: any) => {
          if (Array.isArray(chanelName)) {
            if (chanelName.includes(data.subscribedChannel)) callback(data)
          } else if (data.subscribedChannel == chanelName) callback(data)
        },
      })
      this.clients[uuid].subscribe({
        channels: Array.isArray(chanelName) ? chanelName : [chanelName],
      })
    }
  }

  unsubscribe = (uuid: string, chanelName: string) => {
    if (this.clients[uuid]) {
      this.clients[uuid].unsubscribe({ channels: [chanelName] })
    }
  }

  unsubscribeAll = () => {
    Object.keys(this.clients).forEach((uuid) => {
      this.clients[uuid].unsubscribeAll()
    })
  }
}
