import { allowedFileFormats } from '@/constant/chats.constants'
import {
  IconExclamationCircle,
  IconFile,
  IconFileMusic,
  IconFileTypeDoc,
  IconFileTypeDocx,
  IconFileTypePdf,
  IconPlus,
  IconSend,
} from '@tabler/icons-react'
import Loader from '../loader'

export function SendMediaDisplay({
  sendingMedia,
  setSendingMedia,
  sendMediaDoc,
  displayMediaData,
  setFileSelected,
  setDisplayMediaData,
  message,
  setMessage,
  setDocumentSection,
}) {
  return (
    <div
      id='mediaDisplay'
      className={`absolute left-0 top-0 z-[999999] h-[100%] w-[100%] overflow-hidden bg-[#f0f0f0] dark:bg-slate-900`}
    >
      <div
        className={`absolute left-0 top-0 flex h-16 w-full items-center justify-start bg-white p-2 dark:bg-slate-950 `}
      >
        <button
          onClick={() => {
            const photoInput = document.getElementById(
              'photoInput'
            ) as HTMLInputElement
            photoInput.value = ''

            setFileSelected(false)
            setDisplayMediaData(null)
            setMessage('')
            setDocumentSection(false)
          }}
          className={`rotate-45`}
        >
          <IconPlus />
        </button>
      </div>

      <div
        className={`absolute left-0 top-1/2 grid h-[calc(100%-8rem)] max-h-[calc(100dvh-8rem)] w-full -translate-y-1/2 place-content-center overflow-hidden px-2 py-4`}
      >
        {sendingMedia && (
          <div className='absolute z-10 grid h-full w-full place-content-center bg-white bg-opacity-50'>
            <Loader />
          </div>
        )}
        {(
          ['image', 'video', 'document', 'audio'].includes(
            displayMediaData?.type
          )
            ? true
            : allowedFileFormats.all.includes(displayMediaData?.format)
        ) ? (
          <>
            {displayMediaData?.type == 'image' && (
              <img
                id='imageDisplay'
                className='h-[calc(100dvh-10rem)] w-full rounded-xl object-contain sm:h-[calc(100dvh-10rem)]'
                src={displayMediaData?.src}
                alt={displayMediaData?.fileName}
              />
            )}
            {displayMediaData?.type == 'video' && (
              <video
                id='videoDisplay'
                className='h-[calc(100dvh-10rem)] w-full rounded-xl object-contain p-4 sm:h-[calc(100dvh-10rem)]'
                controls
              >
                <source src={displayMediaData?.src} />
              </video>
            )}
            {displayMediaData?.type == 'document' && (
              <div className={`flex flex-col items-center justify-center`}>
                {displayMediaData?.format == 'pdf' && (
                  <IconFileTypePdf size={68} />
                )}
                {displayMediaData?.format == 'doc' && (
                  <IconFileTypeDoc size={68} />
                )}
                {displayMediaData?.format == 'docx' && (
                  <IconFileTypeDocx size={68} />
                )}
                <div className={`text-xl text-slate-400`}>
                  {displayMediaData?.filename}
                </div>
              </div>
            )}
            {displayMediaData?.type == 'audio' && (
              <div className={`flex flex-col items-center justify-center`}>
                <IconFileMusic size={68} />
                <div className={`text-xl text-slate-400`}>
                  {displayMediaData?.filename}
                </div>
              </div>
            )}
          </>
        ) : (
          <div className={`flex flex-col items-center justify-center`}>
            <IconFile size={68} />
            <div className={`text-xl text-slate-400`}>
              {displayMediaData?.filename}
            </div>
            <div className={`flex items-center gap-2 text-sm text-red-400`}>
              <IconExclamationCircle size={18} />
              Can't Send this format file.
            </div>
          </div>
        )}
      </div>

      <div
        className={`absolute bottom-0 left-0 flex min-h-16 w-full items-end justify-around gap-2 bg-white p-2 dark:bg-slate-950`}
      >
        <textarea
          onChange={(e: any) => {
            setMessage(e?.target?.value)
          }}
          disabled={
            !['image', 'video', 'document'].includes(displayMediaData?.type)
          }
          rows={1}
          className='h-full max-h-20 min-h-11 w-[90%] rounded-xl border border-slate-200 bg-white px-5 pt-2 placeholder-slate-300 focus:border-slate-400 focus:outline-none disabled:cursor-not-allowed disabled:bg-slate-100 dark:border-slate-700 dark:bg-slate-900 dark:placeholder-slate-600 dark:focus:border-slate-500'
          placeholder='Add notes'
          value={message}
        />
        <button
          disabled={
            !(['image', 'video', 'document', 'audio'].includes(
              displayMediaData?.type
            )
              ? true
              : allowedFileFormats.all.includes(displayMediaData?.format))
          }
          onClick={() => {
            setSendingMedia(true)
            sendMediaDoc(displayMediaData)
            const photoInput = document.getElementById(
              'photoInput'
            ) as HTMLInputElement
            photoInput.value = ''
          }}
          className={`cursor-pointer rounded-full bg-gradient-to-r from-purple-400 to-violet-600 p-2.5 disabled:cursor-not-allowed disabled:from-slate-300 disabled:to-slate-500 dark:from-blue-400 dark:to-indigo-600`}
        >
          <IconSend color='white' />
        </button>
      </div>
    </div>
  )
}
