import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import MessageGroups from './MessageGroups'
import useUser from '@/api/hooks/useUser'
import SendMessage from './SendMessage'
import CardTitle from './CardTitle'
import DateGroups from './DateGroups'
import ImageDisplayFull from './ImageDisplayFull'
import { fetchAllMessagesApi } from '@/api/services/chat.service'
import useEvents from '@/api/hooks/useEvents'

const audio = new Audio('Audio/notification.ogg')

// Split messages into diffrent groups as per dates
const groupingInDate = async (messages) => {
  const val = !messages?.data?.[0]
    ? { today: [] }
    : await messages?.data?.reduce(async (groups: any, note: any) => {
        let date = dayjs(parseInt(note.created_at)).format('DD MMM, YYYY')
        const initials =
          note.sender_name.split(' ')[0][0] +
          '' +
          (note.sender_name.split(' ')?.[1]?.[0] || '')
        note['initials'] = initials
        if (note?.category == 'media' && note?.asset_url) {
          const fileName = note?.asset_url?.split('/')
          note['fileName'] = fileName[fileName.length - 1]
        }
        if (date === dayjs().format('DD MMM, YYYY')) date = 'Today'
        const group = (await groups)[date] || []
        group.push(note)
        return { ...(await groups), [date]: group }
      }, Promise.resolve({}))
  return val
}

export function Chat({ channel_id, pusher }) {
  const { user }: any = useUser()
  const chatMessageRef = useRef(null)
  const messageData = { today: [] }

  const [imageSection, setImageSection] = useState<string>('')
  const [messages, setMessages] = useState<any>({})
  const [documentSection, setDocumentSection] = useState<boolean>(false)
  const [notesGroup, setNotesGroup] = useState(messageData)

  const [alertGlow, setAlertGlow] = useState(false)
  const alertAnimationClasses = 'relative animate-borderColorPulse rounded-xl'

  const { getEventsForOrder, removeAlertForOrderId } = useEvents()

  const orderEvents = getEventsForOrder(channel_id)?.alerts ?? []

  const chatEvents = orderEvents?.filter((entry: any) => {
    return entry?.eventType === 'CHAT'
  })

  useEffect(() => {
    if (chatEvents?.length > 0) {
      setAlertGlow(true)
      removeAlertForOrderId(
        channel_id,
        orderEvents?.filter((entry: any) => {
          return entry?.eventType !== 'CHAT'
        })
      )
    }
    if (chatEvents?.length === 0 && alertGlow) {
      setTimeout(() => {
        setAlertGlow(false)
      }, 5000)
    }
  }, [chatEvents])

  // fetch all messages
  useEffect(() => {
    fetchAllMessagesApi(channel_id)
      .then((messageData: any) => {
        setMessages(messageData.data.data)
      })
      .catch(() => {
        setMessages({})
      })
  }, [channel_id])

  // Pubnub subscription
  useEffect(() => {
    pusher.subscribe('CHAT', [`ER-CHAT-${channel_id}`], addNewMessage)
    return () => {
      pusher.unsubscribe('CHAT', [`ER-CHAT-${channel_id}`])
    }
  }, [channel_id])

  // Pubnub callback function to fecth new messages
  function addNewMessage(newMessage: any) {
    setMessages((val: any) => {
      const data = {
        asset_url: newMessage?.message?.assetUrl || null,
        category: newMessage?.message?.category || '',
        channel_id: newMessage?.channel || '',
        content: newMessage?.message?.content || '',
        created_at: newMessage?.timetoken.slice(0, -4) || '',
        format: newMessage?.message?.format || '',
        initials: newMessage?.message?.initials || '',
        message_id: newMessage?.message?.messageId || '',
        sender_id: newMessage?.message?.senderId || '',
        sender_name: newMessage?.message?.senderName || '',
        sender_role: newMessage?.message?.senderRole || '',
        type: newMessage?.message?.type || '',
      }
      const newData: any = val?.data ? [data, ...val.data] : [data]
      const newMessagesData = { size: val.size + 1, data: newData }

      // notify users for new messages
      if (newMessage?.message?.senderId != user?.userId) {
        navigator && navigator.vibrate([200, 100, 200, 900])
        audio.play()
      }
      return newMessagesData
    })
  }

  useEffect(() => {
    groupingInDate(messages).then((data) => setNotesGroup(data))
    // Scroll to the latest message(bottom)
    chatMessageRef?.current?.scrollTo(0, chatMessageRef?.current?.scrollHeight)
  }, [messages])

  return (
    <div className={`m-1 p-0 ${alertGlow && alertAnimationClasses}`}>
      <CardTitle title='Chat' className={'w-full '} />
      <div
        className={`relative mx-auto mb-5 flex h-full max-h-[40vh] min-h-[70vh] w-full flex-col gap-2 overflow-hidden rounded-b-xl border border-t-0 bg-white px-3 py-1 shadow dark:bg-slate-900`}
      >
        <div
          ref={chatMessageRef}
          className={`mb-14 flex max-h-[calc(100%-4rem)] flex-col-reverse overflow-y-scroll overscroll-none scroll-smooth `}
        >
          {Object.keys(notesGroup)?.map((group: any, i: number) => (
            <div className={`relative`} key={`group-${i}`}>
              <DateGroups group={group} />
              <MessageGroups
                channel_id={channel_id}
                sender_id={user?.userId}
                notesGroup={notesGroup}
                group={group}
                setImageSection={setImageSection}
              />
            </div>
          ))}
        </div>
        <SendMessage
          channel_id={channel_id}
          setMessages={setMessages}
          documentSection={documentSection}
          setDocumentSection={setDocumentSection}
          sender_id={user?.userId}
          sender_name={user?.fullName}
          sender_role={user?.role}
          sender_platform={'ER_PORTAL'}
          sender_contact={user?.email}
        />
        <ImageDisplayFull
          imageSection={imageSection}
          setImageSection={setImageSection}
        />
      </div>
    </div>
  )
}
