import { getURLParameters } from "@/utils";
import { useEffect, useState } from "react";

export function useRole() {
  const obj: any = getURLParameters(window.location.href);
  const roles = {
    ambulance: {
      displayName: 'Live Ambulance',
      email: 'liveAmbulance@example.com',
    },
    hospital: {
      displayName: 'Hospital',
      email: 'hospital@example.com',
    },
  };

  const [user, setUser] = useState({
    displayName: 'Dashboard User',
    email: 'dashboard@example.com',
  });

  useEffect(() => {
    if (obj.role && roles[obj.role]) {
      setUser(roles[obj.role]);
    }

    if (obj.name) {
      setUser(prev => ({ ...prev, displayName: obj.name }));
    }

    if (obj.email) {
      setUser(prev => ({ ...prev, email: obj.email }));
    }
  }, []);

  return { user };
}
