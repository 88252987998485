import { colors } from '@/constant/chats.constants'
import React from 'react'

const Messages = React.lazy(() => import('./Messages'))

export default function MessageGroups({
  notesGroup,
  group,
  setImageSection,
  sender_id,
  channel_id,
}) {
  // add diffrent colors to diffrent users
  function ascii(a) {
    return a.charCodeAt(0)
  }
  function addColors(data) {
    let c = data.sender_name
      .split('')
      .map(ascii)
      .reduce((a, b) => a + b)
    while (colors.length <= c) {
      c = (c + '').split('').reduce((a, b) => a + parseInt(b), 0)
    }
    data['color'] = colors[c]
  }
  return (
    <div className='flex flex-col-reverse gap-3'>
      {notesGroup[group].map((data: any) => {
        addColors(data)
        return (
          <Messages
            sender_id={sender_id}
            messageData={data}
            setImageSection={setImageSection}
            channel_id={channel_id}
            key={`message-${data?.message_id}`}
          />
        )
      })}
    </div>
  )
}
