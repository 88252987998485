import { axiosInstance } from '@/api/axios-interceptor'
import request from '@/api/request'
import { contentType } from '@/constant/chats.constants'

const token = import.meta.env.VITE_AUTH

const urls = {
  fetchAllMessagesUrl: (channel_id: string) =>
    `/ers/v1/chats/v1/channels/${channel_id}/messages/all`,
  sendMessageUrl: (channel_id: string) =>
    `/ers/v1/chats/v1/channels/${channel_id}/messages`,
  fetchUploadSignedUrl: (channel_id: string, fileName: string) =>
    `/ers/v1/fru/v1/fs/d/upload/case/${channel_id}/file/${fileName}`,
  fetchReadSignedURL: (channel_id: string, fileName: string) =>
    `/ers/v1/fru/v1/fs/d/read/case/${channel_id}/file/${fileName}`,
}

export const fetchAllMessagesApi = async (channel_id) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  const response = request('GET', urls.fetchAllMessagesUrl(channel_id))
  return response
}

export const sendMessageApi = async (channel_id, data) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  axiosInstance.defaults.headers.common['content-type'] = `application/json`
  const response = request('POST', urls.sendMessageUrl(channel_id), data)
  return response
}

export const fetchUploadSignedApi = async (channel_id, fileName) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  const response = request(
    'GET',
    urls.fetchUploadSignedUrl(channel_id, fileName)
  )
  return response
}

export const fetchReadSignedApi = async (channel_id, fileName) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  const response = request(
    'GET',
    urls.fetchReadSignedURL(channel_id, fileName)
  )
  return response
}

export const UploadMediaApi = async (URL, fileData, format) => {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`
  axiosInstance.defaults.headers.common['content-type'] = contentType[format]
  axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
  axiosInstance.defaults.headers.common['Access-Control-Allow-Methods'] =
    'GET, PUT, POST, PATCH, DELETE, HEAD'
  axiosInstance.defaults.headers.common['Access-Control-Max-Age'] = '86400'
  axiosInstance.defaults.headers.common['Access-Control-Allow-Headers'] = '*'
  const response = request('PUT', URL, fileData)
  return response
}