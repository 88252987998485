// src/redux/usersSlice.js
import { verifyAuthToken } from '@/api/thunks/user.thunk'
import { userInitialState } from '@/store/redux/user/userStore.constant'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { orderEventsInitialState } from './eventsStore.constant'

const orderEventsSlice = createSlice({
  name: 'events',
  initialState: orderEventsInitialState,
  reducers: {
    addAlert: (state, action) => {
        const { orderId, alert, globalRead } = action.payload;

        if (!state[orderId]) {
        state[orderId] = { alerts: [alert], globalRead: globalRead };
        }
        if(state[orderId].alerts) {
            state[orderId] = {
                alerts: [...state[orderId].alerts, alert],
                globalRead: globalRead
            }
        }
        else {
            state[orderId] = {
                alerts: [alert],
                globalRead: globalRead
            }
        }
    },
    updateGlobalRead : (state, action) => {
        const {orderId, globalRead} = action.payload;
        state[orderId] = {alerts: state[orderId]?.alerts, globalRead: globalRead};
    },
    removeAlert: (state, action) => {
        const {orderId, alerts} = action.payload;
        state[orderId] = {
            alerts: alerts,
            globalRead: false,
        }
    },
    markAlertRead: (state, action) => {
        const { orderId, alertIndex } = action.payload;

        if (state[orderId] && state[orderId].alerts[alertIndex]) {
        state[orderId].alerts[alertIndex].read = true;
        }
    },
    },
})
export const { addAlert, markAlertRead, removeAlert, updateGlobalRead } = orderEventsSlice.actions;

export default orderEventsSlice.reducer;
