import { IconArrowLeft, IconLoader } from '@tabler/icons-react'
import { useState } from 'react'

export default function ImageDisplayFull({ imageSection, setImageSection }) {
  const imageClasses = ['cursor-zoom-in scale-1', 'cursor-zoom-out scale-[2]']
  const [loading, setLoading] = useState(true)
  const [showImageSection, setShowImageSection] = useState<boolean>(false)
  return (
    <>
      {imageSection && (
        <div className='fixed left-0 top-0 z-[100000] box-border grid h-[100vh] w-[100vw] place-content-center bg-black bg-opacity-50 backdrop-blur-sm'>
          <button
            onClick={() => {
              setImageSection('')
              setShowImageSection(false)
              setLoading(true)
            }}
            className='absolute left-0 top-0 p-5'
          >
            <IconArrowLeft color='white' />
          </button>
          <div
            onClick={() => {
              setImageSection('')
              setShowImageSection(false)
              setLoading(true)
            }}
            className='absolute left-0 top-0 -z-10 h-full w-full '
          ></div>
          {loading && (
            <IconLoader
              color='white'
              className='mx-auto animate-spin'
              size={52}
            />
          )}
          <img
            onLoad={() => {
              setLoading(false)
            }}
            onClick={() => setShowImageSection((val: boolean) => !val)}
            className={
              'max-h-[95vh] max-w-[95vw] text-xs text-slate-400 transition-[transform] ' +
              (showImageSection ? imageClasses[1] : imageClasses[0]) +
              (loading ? ' hidden' : ' block')
            }
            src={imageSection}
          ></img>
        </div>
      )}
    </>
  )
}
