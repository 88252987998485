import useAudioRecording from '@/hooks/use-audio-recording'
import {
  IconPlayerPauseFilled,
  IconPlayerPlayFilled,
  IconSend,
  IconTrashFilled,
} from '@tabler/icons-react'
import { useTheme } from '../theme-provider'
import { Icon } from '@iconify/react/dist/iconify.js'
import { useEffect, useRef, useState } from 'react'

export function SendAudio({
  documentSection,
  setDisplayMediaData,
  message,
  sendMediaDoc,
  setRecordingOn,
}) {
  const mic = useRef<HTMLDivElement>(null)
  const { theme } = useTheme()
  const [sendAudio, setSendAudio] = useState<boolean>(false)
  const {
    recordButton,
    pauseButton,
    progress,
    isRecordingPaused,
    isRecordPlaying,
    micOn,
    audioBlob,
    // wavesurfer,
    // setIsRecordingPaused,
    setIsRecordPlaying,
    setMicOn,
    PlayRecording,
    DeleteRecording,
    PauseRecording,
    CreateWaveSurfer,
    // StartRecording,
  } = useAudioRecording(mic.current)

  useEffect(() => {
    // initialization of audio waves
    if (micOn && mic?.current) CreateWaveSurfer(mic.current)
  }, [micOn, mic])

  useEffect(() => {
    audioBlob && sendAudio && convertAudioToBinary()
  }, [audioBlob])

  function convertAudioToBinary() {
    const reader = new FileReader()

    reader.onloadend = () => {
      const audioData = {
        src: audioBlob,
        modifiedFileName: 'Audio_' + Date.now() + '.webm',
        filename: 'Audio_' + Date.now() + '.webm',
        filesize: 0,
        category: 'media',
        type: 'audio',
        format: 'webm',
        mediaData: reader.result,
      }

      setDisplayMediaData(() => audioData)
      sendMediaDoc(audioData)
    }

    reader.readAsArrayBuffer(audioBlob)
    setSendAudio(false)
  }

  return (
    <>
      {micOn && (
        <>
          <button
            className='px-2.5 py-2.5'
            onClick={() => {
              DeleteRecording()
              setRecordingOn(() => false)
            }}
          >
            <IconTrashFilled
              className={`transition-[transform,color] ${documentSection ? 'rotate-45 text-red-400' : 'rotate-0'}`}
              width={20}
            />
          </button>

          <div className='flex max-h-20 min-h-11 w-[90%] items-center gap-3 rounded-xl border border-slate-200 bg-white px-4  dark:border-slate-700 dark:bg-slate-900 dark:placeholder-slate-600 dark:focus:border-slate-500'>
            {isRecordingPaused == true ? (
              <div
                onClick={() => {
                  PlayRecording()
                  setIsRecordPlaying((val: boolean) => !val)
                }}
              >
                {isRecordPlaying ? (
                  <IconPlayerPauseFilled
                    size={16}
                    color={theme == 'dark' ? '#3c5884' : '#6b7280'}
                  />
                ) : (
                  <IconPlayerPlayFilled
                    size={16}
                    color={theme == 'dark' ? '#3c5884' : '#6b7280'}
                  />
                )}
              </div>
            ) : (
              <div className='relative grid place-content-center'>
                <div className='absolute h-[16px] w-[16px] animate-ping rounded-full bg-red-600 '></div>
                <div className='h-[16px] w-[16px] scale-50 rounded-full bg-red-600 '></div>
              </div>
            )}
            <p ref={progress} className='text-xs text-gray-400' id='progress'>
              00:00
            </p>
            <div ref={mic} className='w-[95%]'></div>
          </div>
        </>
      )}
      {!message && (
        <>
          {micOn && (
            <button
              ref={pauseButton}
              className='px-3 py-2.5'
              onClick={PauseRecording}
            >
              {isRecordingPaused ? (
                <Icon
                  icon='solar:microphone-line-duotone'
                  style={{ color: 'black' }}
                  width='1.5em'
                  height='1.5em'
                />
              ) : (
                <IconPlayerPauseFilled width={20} />
              )}
            </button>
          )}
          <button
            ref={recordButton}
            onClick={() => {
              setMicOn(() => true)
              setRecordingOn(() => true)
            }}
            className='px-3 py-2.5'
          >
            <Icon
              icon='solar:microphone-line-duotone'
              style={{ color: 'black' }}
              width='1.5em'
              height='1.5em'
            />
          </button>

          {micOn && (
            <button
              onClick={() => {
                setSendAudio(true)
                DeleteRecording()
                setRecordingOn(() => false)
              }}
              className='rounded-full bg-gradient-to-r from-purple-400 to-violet-600 p-2.5 dark:from-blue-400 dark:to-indigo-600'
            >
              <IconSend color='white' />
            </button>
          )}
        </>
      )}
    </>
  )
}
