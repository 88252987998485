import { Icon } from '@iconify/react/dist/iconify.js'
import { cx } from 'class-variance-authority'

interface CardTitleProps {
  title: string
  className?: string
}

export default function CardTitle({ title, className }: CardTitleProps) {
  return (
    <div
      className={cx(`mx-auto w-[90%] overflow-hidden rounded-t-xl border-t border-x bg-[#F7F7F7] text-center font-sans text-black shadow `, className ?
         `${className}` : ' '
      )}
    >
      <div className={`flex w-full items-center justify-center gap-3 p-4`}>
        <Icon
          icon='solar:chat-line-line-duotone'
          style={{ color: '#161B1F' }}
          width='1.25rem'
        />
        {title}
      </div>
    </div>
  )
}
