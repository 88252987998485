import React, { useState, useRef, useEffect, memo } from 'react'
import Draggable from 'react-draggable'
import JaasMeet from './JaasMeet'
import TopBar from './Topbar'
import useScreenSize from '@/hooks/use-screen-size'
import { Card, CardContent } from '@/components/ui/card'
import { Icon } from '@iconify/react'
import { useParams } from 'react-router-dom'
import useUser from '@/api/hooks/useUser'
import useOrders from '@/api/hooks/useOrder'
import useEvents from '@/api/hooks/useEvents'

interface LiveStreamSectionProps {
    setShowLiveStream: React.Dispatch<React.SetStateAction<boolean>>
    hasJoinedCall: boolean
    setHasJoinedCall: React.Dispatch<React.SetStateAction<boolean>>
    containerRef: React.RefObject<HTMLElement>
}

interface DimensionsState {
    width: number
    height: number
    transition: boolean
    x?: number
    y?: number
}

const remToPixels = (remValue: number): number => {
    return remValue * 16
}

const LiveStreamSection: React.FC<LiveStreamSectionProps> = ({ setShowLiveStream, hasJoinedCall, setHasJoinedCall, containerRef }) => {
    const { selectedOrderId } = useOrders()
    const [dimensions, setDimensions] = useState<DimensionsState>({ width: 30, height: 30, transition: false })
    const screenSize = useScreenSize()
    const isMobile: boolean = screenSize.width <= 768

    const [alertGlow, setAlertGlow] = useState(false);
    const alertAnimationClasses = 'relative animate-borderColorPulse rounded-xl';
  
    const { getEventsForOrder , removeAlertForOrderId } = useEvents();
  
    const orderEvents = getEventsForOrder(selectedOrderId)?.alerts ?? [];
  
  
    const videoEvents = orderEvents?.filter((entry: any)=> {
      return entry?.eventType === 'VIDEO';
    })

    // Function to update dimensions
    const handleSizeChange = (width: number, height: number): void => {
        setDimensions({
            width,
            height,
            transition: true,
            x: dimensions.x,
            y: dimensions.y,
        })
    }

    // Calculate bounds for Draggable based on containerRef and screen size
    const bounds = {
        left: !isMobile && containerRef?.current?.offsetWidth ? -(containerRef.current?.offsetWidth - remToPixels(dimensions.width)) : 0,
        top: containerRef?.current?.offsetHeight ? -(containerRef.current.offsetHeight - remToPixels(dimensions.height)) : 0,
        right: 0,
        bottom: 0,
    }

    // Dynamically calculate width for mobile screens
    const boxWidth: string = isMobile ? `${screenSize.width}px` : `${dimensions.width}rem`

    //   useEffect(() => {
    //     console.log('Changed: ',selectedOrderId)
    // }, [selectedOrderId]);

    useEffect(()=> {
      if(videoEvents?.length > 0) {
        setAlertGlow(true);
        removeAlertForOrderId(selectedOrderId, orderEvents?.filter((entry: any) => {
          return entry?.eventType !== 'VIDEO';
        }))
      }
      if(videoEvents?.length ===0 && alertGlow) {
        setTimeout(()=> {
          setAlertGlow(false);
        }, 5000);
      }
    }, [videoEvents]);

    return (
        <Card className={`mx-auto mb-5 overflow-hidden border-none bg-transparent font-sans text-black ${alertGlow && alertAnimationClasses}`}>
            <div className={`flex items-center justify-center gap-2 bg-zinc-100 py-3 text-center font-semibold text-zinc-700`}>
                <Icon icon={'solar:videocamera-record-line-duotone'} fontSize={20} /> {'Live Cam'}
            </div>
            {/* <TopBar setShowLiveStream={setShowLiveStream} setDimensions={setDimensions} handleSizeChange={handleSizeChange} setHasJoinedCall={setHasJoinedCall} /> */}
            <div className=' text-center'>
                <CardContent className='h-[50vh] max-h-[70vh] overflow-y-scroll p-0 '>
                    {selectedOrderId ? (
                        <JaasMeet roomName={selectedOrderId} hasJoinedCall={hasJoinedCall} setHasJoinedCall={setHasJoinedCall} />
                    ) : (
                        <div>
                            <p>No Order Id found.</p>
                        </div>
                    )}
                </CardContent>
            </div>
        </Card>
    )
}

export default memo(LiveStreamSection)
